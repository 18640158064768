
import Icon from "@/components/Icon.vue";
import Loading from "@/components/Loading.vue";
import PrintPage2 from '@/components/PrintPage2.vue';
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { GroupTypeQuestion } from "@/dtos/reports/CalculateResultGroupTypeQuestionDTO";
import { useAlert } from '@/hooks/alert';
import ExameService from "@/services/ExameService";
import ReportService from "@/services/ReportService";
import { maskCpf } from "@/utils/stringFunctions";
import vm from "@/viewModels/MainViewModel";
import { defineComponent, nextTick, onBeforeMount, onUnmounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

interface CertificateState {
    examAdmin: ExamResponseDTO;
    calcs: GroupTypeQuestion[];
    QRCodeUrl: string;
    exam: GestorExamDTO;
    day: string;
    month: string;
    year: string;
    qtdQuestionsApproved: number;
    loading: boolean;
}


const CertificateDFView = defineComponent({
    components: { PrintPage2, Icon, Loading },
    setup(_props, context) {
        const { replace } = useRouter();
        const alert = useAlert();
        const { params } = useRoute();


        const state = reactive<CertificateState>({
            examAdmin: {} as ExamResponseDTO,
            calcs: [],
            QRCodeUrl: '',
            exam: {} as GestorExamDTO,
            day: '',
            month: '',
            year: '',
            qtdQuestionsApproved: 0,
            loading: true,
        });



        const onLoadErrorDispatch = (errorMessage: string) => {
            const message = { type: 'onLoadError', content: errorMessage };
            window.parent.postMessage(message, '*');
        };

        const afterPrint = () => {
            context.emit('afterPrint');
        };

        const loadReport = async () => {
            try {
                console.log(params)
                const [request1] = ExameService.GetExameGestorBySchedulingId(params.schedulingId as string);
                state.exam = await request1;
                const dateObject = new Date(state.exam.schedulingDate!);
                console.log(state.exam)
                state.day = String(dateObject.getUTCDate()).padStart(2, '0');
                state.month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
                state.year = dateObject.getUTCFullYear().toString();
                const [request] = ReportService.CalculateResultGroupTypeQuestion(params.schedulingId as string);
                const [examAdminPromise] = ExameService.GetExameGestor(params.schedulingId as string);

                const [resp, examAdmin] = await Promise.all([request, examAdminPromise]);

                state.calcs = resp;
                state.examAdmin = examAdmin;
                state.exam.candidate.identityDocument = maskCpf(state.exam.candidate.identityDocument)

                const total = resp[resp.length - 1];

                const porcentagemDesejada = examAdmin.minPercentCorrectAnswers / 100;
                state.qtdQuestionsApproved = Math.ceil(total.totalQuestion * porcentagemDesejada);

                await nextTick();
                setTimeout(() => window.print(), 600);
                state.loading = false;
            } catch (error: any) {
                state.loading = false;
                onLoadErrorDispatch(error?.toString() ?? "");
                alert({
                    title: "Não foi possivel carregar o resultado",
                    message: error.message,
                    actions: [{ title: "Voltar", action: () => replace('exams') }],
                });
            }
        };



        onBeforeMount(async () => {
            await loadReport();
        });

        onUnmounted(async () => {
            window.removeEventListener('afterprint', afterPrint);
        });


        return { state, print, vm };
    },
});

export default CertificateDFView;
