
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import Switch from "@/components/ToogleSwitch.vue";
import { useAlert } from '@/hooks/alert';
import { maskCpf, removeCpfMask } from '@/utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watchEffect } from "vue";
import Alert from "@/components/Alert.vue";
import { useOpenViewerRoomModal } from "@/hooks/openViewerRoomModal";
import ViewerCheckInResponseDTO from "@/dtos/ViewerCheckInResponseDTO";
import ViewerCheckInService from "@/services/ViewerCheckInService";
import { format } from "date-fns";
import ViewerCheckInDoCheckOutResponseDTO from "@/dtos/ViewerCheckInDoCheckOutResponseDTO";
import ViewerCheckInExamDTO from "@/dtos/ViewerCheckInExamDTO";
import CheckInExamCard from "./CheckInExamCard.vue";

const alert = useAlert();
const openRoomModal = useOpenViewerRoomModal();

interface CheckOutResult {
    success: boolean,
    errorMessage: string | null,
    payload: ViewerCheckInDoCheckOutResponseDTO | null
}

const ViewerCheckinView = defineComponent({
    components: { Switch, CheckInExamCard, Button, Loading, Alert },
    setup() {

        const searchText = ref<string>('');
        const loading = ref<boolean>(false);
        const loadingExams = ref<boolean>(false);

        const viewer1Cpf = vm.user!.cpf;
        const schedulingLocal = vm.schedulingLocal;
        const isRoomOpened = ref<boolean>(false);
        const checkIn = ref<ViewerCheckInResponseDTO>();
        const checkInOpenedAt = ref<string>('');

        const checkInExams = ref<ViewerCheckInExamDTO[]>([]);

        let timerGetExams: number;

        const clearExams = () => {
            checkInExams.value = [];
        };

        const fetchData = async () => {
            loading.value = true;
            try {
                await findCheckIn();
            }
            catch (error) {
                alert({ message: "Erro ao carregar a sala de prova" });
            }
            finally {
                loading.value = false;
            }
        };

        const fetchExams = async () => {
            if (loadingExams.value || !isRoomOpened.value)
                return;

            try {
                var checkInId = checkIn.value!.id;
                var exams = await getCheckInExams(checkInId);

                checkInExams.value = [];
                checkInExams.value = exams;
            }
            catch (error) {
                alert({
                    title: "Erro",
                    message: error as string,
                    color: "red",
                });
            }
        };

        const openRoom = async () => {
            openRoomModal({
                title: "Abrir sala",
                message: "login aqui",
                actions: [
                    { title: "Cancelar" },
                    {
                        title: "Abrir sala",
                        primary: true,
                        action: async (checkin: ViewerCheckInResponseDTO) => {
                            isRoomOpened.value = true;
                            checkIn.value = checkin;
                            checkInOpenedAt.value = format(new Date(checkIn.value.checkInDate), 'dd/MM/yyyy HH:mm:ss');
                            alert({
                                title: "OK",
                                message: "Sala aberta com sucesso!",
                                icon: "check",
                                color: "green",
                            });
                        }
                    }
                ]
            });
        };

        const checkOut = async (): Promise<CheckOutResult> => {
            loading.value = true;
            try {
                const [request] = ViewerCheckInService.CheckOut({
                    checkInId: checkIn.value!.id,
                });

                const response = await request;
                return { success: true, errorMessage: null, payload: response };
            } catch (error) {
                return { success: false, errorMessage: error as string, payload: null };
            } finally {
                loading.value = false;
            }
        };

        const closeRoom = async () => {
            isRoomOpened.value = false;

            var result = await checkOut();

            if (!result.success) {

                alert({
                    title: "Erro",
                    message: result.errorMessage!,
                    color: "red",
                });

                isRoomOpened.value = true;
                return;
            }

            alert({
                title: "Sucesso",
                message: `Sala fechada com sucesso!`,
            });

            clearExams();
        };

        const confirmOpen = () => {
            alert({
                title: "Confirmação",
                message: "Tem certeza que deseja abrir a sala?",
                actions: [
                    { title: "Não" },
                    {
                        title: "Sim",
                        primary: true,
                        action: async () => {
                            await openRoom();
                        }
                    }
                ]
            });
        };

        const confirmClose = () => {
            alert({
                title: "Confirmação",
                message: "Tem certeza que deseja fechar a sala?",
                actions: [
                    { title: "Não" },
                    {
                        title: "Sim",
                        primary: true,
                        action: async () => {
                            await closeRoom();
                        }
                    }
                ]
            });
        };

        const findCheckIn = async () => {
            try {
                var response = await ViewerCheckInService.FindCheckIn({
                    viewerCpf: viewer1Cpf,
                    schedulingLocal: schedulingLocal
                })[0];

                checkIn.value = response;
                isRoomOpened.value = true;
                checkInOpenedAt.value = format(new Date(response.checkInDate), 'dd/MM/yyyy HH:mm:ss');
            }
            catch (error) {
                isRoomOpened.value = false;
                checkInOpenedAt.value = '';
            }
        };

        const getCheckInExams = async (checkInId: string): Promise<ViewerCheckInExamDTO[]> => {
            try {
                loadingExams.value = true;
                var response = await ViewerCheckInService.GetCheckInExams(checkInId)[0];
                return response;
            }
            catch (error) {
                alert({
                    title: "Erro",
                    message: error as string,
                    color: "red",
                });
            }
            finally {
                loadingExams.value = false;
            }
            return [];
        };

        const clearSearchText = async () => {
            searchText.value = '';
        };

        const filteredExams = computed<ViewerCheckInExamDTO[]>((): ViewerCheckInExamDTO[] => {
            return checkInExams.value.filter(exam => 
                exam.name.toLowerCase().includes(searchText.value.toLowerCase())
                    || removeCpfMask(exam.cpf).includes(removeCpfMask(searchText.value))
            );
        });

        onMounted(async () => {
            await fetchData();
            await fetchExams();
        });

        const startPeriodicFetch = () => {
            clearInterval(timerGetExams);
            timerGetExams = setInterval(async () => {
                await fetchExams();
            }, 30000);
        };
        const stopPeriodicFetch = () => {
            clearInterval(timerGetExams);
        };

        onUnmounted(async () => {
            stopPeriodicFetch()
        });

        watchEffect(() => {
            if (isRoomOpened.value) {
                console.log('startPeriodicFetch');
                startPeriodicFetch();
            }

            if (!isRoomOpened.value) {
                console.log('stopPeriodicFetch');
                stopPeriodicFetch();
            }
        });

        return {
            vm,
            maskCpf,
            searchText,
            clearSearchText,
            loading,
            loadingExams,
            fetchExams,
            confirmClose,
            confirmOpen,
            isRoomOpened,
            checkIn,
            checkInOpenedAt,
            checkInExams,
            filteredExams
        };
    },

});

export default ViewerCheckinView;
